<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_from_input"
      width="950"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Payment
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-text-field
                    v-model="name"
                    label="Name"
                    :rules="nameRules"
                    required
                    ></v-text-field>
                    <v-text-field
                    v-model="mdr"
                    :rules="mdrRules"
                    label="MDR"
                    required
                    ></v-text-field>
                    <v-textarea
                      v-model="note"
                      label="Note"
                    ></v-textarea>
               </v-form>
               <input type="file" style="display:none; " id="foto_profile" ref="foto_profile" accept="image/*" @change="eventChange">
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MixinGlobal from '../../../mixin/index'
export default {
  mixins:[MixinGlobal],
  methods: {
    send_data() {
      const data = new FormData()
      data.append('mdr', this.mdr)
      data.append('note', this.note)
      data.append('name', this.name)
      data.append('entry_by', this.user.fullname)
      data.append('payment_type', "payment")
      data.append('company_id', this.user.company_id)
      return data
    },
    async save () {
      this.loading = true
      const data = this.send_data()
      await this.axios.post('v1/payment/create', data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go('payment','payments')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    async update() {
      this.loading = true
      const data = this.send_data()
      await this.axios.put('v1/payment/update/' + this.edit_data.id, data)
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success Update Payment',
            color_snakbar: 'success'
          })
          await this.go('payment','payments')
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    close () {
      this.reset()
      this.$emit('close')
    },
    reset () {
      this.foto = ''
      this.mdr = ''
      this.note = ''
      this.name = ''
    }
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    name: '',
    note: '',
    mdr: '',
    mdrRules: [
      (v) => !v || /^\d+(\.\d{0,2})?$/.test(v) || 'Please input Decimal Number 0.xx,1.xx'
    ],
    nameRules: [
      v => !!v || 'Field is required'
    ],
    imgurl: 'img/no_img.png',
    foto: null

  }),

  computed: {
    img: function () {
      if (this.edit_data) {
        if (this.edit_data.image_link) {
          return process.env.VUE_APP_IMGLINK + 'payment/' + this.edit_data.image_link
        } else {
          return this.imgurl
        }
      } else {
        return this.imgurl
      }
    }
  },

  created () {
    if (this.edit_data) {
      this.mdr = this.edit_data.mdr
          this.note = this.edit_data.note
          this.name = this.edit_data.name
    }
  }
}
</script>
